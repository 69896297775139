@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.tile {
  margin-bottom: 0;
  height: 100%;
  padding: 0;

  @include screen-sm {
    padding: 0 0.5rem;
  }

  :global {
    .image {
      margin-bottom: 1rem;

      &::after {
        background: $gb_gdds_cl15_50;
        border-color: $gb_white;
        color: $gb_white;
      }

      .description {
        p {
          @include line-clamp(3);
        }

        button {
          position: initial;
          margin-top: 1.5rem;
        }
      }
    }
  }
}

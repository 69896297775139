@import 'styles/base/variables';
@import 'styles/base/mixins';

.noResultsWrapper {
  &.documentFontSize p {
    font-size: 15px !important;
  }
  
  @include screen-sm {
    margin: 2.5rem -1rem 0 -1rem;
  }

  @include screen-md {
    margin: 3.5rem 0 0 0;
  }

  ul,
  .text {
    margin: 0;
    padding: 0;
  }

  &#{&}#{&} .headline {
    margin: 0 0 2rem 0;
    font-size: 1.25rem;
    line-height: 1.75rem;

    @include screen-sm {
      font-size: 1.375rem;
    }

    @include screen-md {
      margin: 0 0 2.5rem 0;
      font-size: 1.5rem;
      line-height: 2rem;
    }
  }

  & .subline {
    margin: 0 0 0.75rem 0;
  }
}

@import '../../../../styles/base/variables';
@import '../../../../styles/base/mixins';

.searchFilersWrapper {
  padding: 2.5rem 0;

  @include screen-md {
    padding: 5rem 0 2.5rem 0;
  }

  & .searchFilers {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    row-gap: 1rem;

    @include screen-md {
      align-items: flex-end;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  & .searchBar {
    margin-bottom: 1rem;

    input[name='search-term'] {
      max-height: 2.75rem;
    }

    button {
      max-height: 2.75rem;
      max-width: 2.75rem;

      > span {
        max-height: 2.75rem;
        max-width: 2.75rem;
      }
    }

    @include screen-sm {
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: center;

      > div {
        max-width: 17.75rem;
      }
    }

    @include screen-md {
      margin-bottom: 0;
      max-height: 2.5rem;

      input[name='search-term'] {
        max-height: 2.5rem;
      }

      button {
        max-height: 2.5rem;
        max-width: 2.5rem;

        > span {
          max-height: 2.5rem;
          max-width: 2.5rem;
        }
      }

      > div {
        width: 20.625rem;
        max-width: 20.625rem;
      }
    }
  }

  & .filters {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;

    > div {
      flex-basis: 100%;
    }

    @include screen-md {
      align-items: flex-end;

      > div > div {
        width: 15rem;
        max-height: 2.5rem;
      }
    }
  }

  select[name='tagfilter'] {
    max-height: 12rem;
    overflow: auto;
  }

  div[name='tag-filter'] + ul > div {
    max-height: 14rem;
  }
}

@import '../../../styles/base/variables';
@import '../../../styles/base/mixins';

.carousel {
  margin-bottom: 1.5rem;

  &#{&} {
    > div {
      margin: 0 0 1.75rem 0;

      @include screen-sm {
        margin: 0 -0.5rem 2.5rem -0.5rem;
      }

      @include screen-md {
        margin: 0 0 2.5rem 0;
      }
    }
  }

  @include screen-md {
    &.withoutControls {
      padding: 0 4.5rem;
    }
  }
}

.actions {
  align-items: center;
  margin-bottom: 0.5rem;
}

.headlineWrapper h2 {
  margin: 0;
}

.url {
  @include screen-sm {
    text-align: right;
  }

  a {
    margin: 0;
    font-size: 1rem;
    min-height: 2.75rem;
    height: 2.75rem;

    @include screen-sm {
      margin: 0 -0.5rem 0 0;
      min-height: 2.5rem;
      height: 2.5rem;
    }

    span {
      font-size: 1rem;
    }
  }
}
